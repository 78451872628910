<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'news-center'
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>